<script setup>
import { ref, watch } from 'vue';
import { usePage } from '@inertiajs/vue3';
import Navigation from "@/Components/Navigation.vue";

const page = usePage();

</script>

<template>
    <main class="bg-black h-full dark antialiased text-white">
        <slot />
    </main>
</template>

<style lang="scss">

</style>

<script>

export default {
    components: {

    },
    data() {
      return {
      }
    },
    methods: {

    },
    mounted() {

    }
}
</script>
