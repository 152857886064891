<script setup>
import { ref } from 'vue';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { Bar } from 'vue-chartjs';
import Layout from '@/Layouts/Admin.vue';
import { Head } from '@inertiajs/vue3';
import TopBar from "@/Components/Admin/TopBar.vue";

// Register chart components
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

// Data from the backend (passed via Inertia)
const props = defineProps({
    totalUsers: Number,
    usersRegisteredToday: Number,
    subscriptionSales: Array, // Expecting an array of sales over time for chart
});

// Chart configuration for subscription sales
const chartData = ref({
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'], // Modify this based on actual months passed from backend
    datasets: [
        {
            label: 'Subscription Sales',
            backgroundColor: '#4CAF50',
            data: props.subscriptionSales, // Use the data passed from backend
        },
    ],
});

const chartOptions = ref({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            title: {
                display: true,
                text: 'Months',
            },
        },
        y: {
            title: {
                display: true,
                text: 'Sales Total',
            },
            beginAtZero: true,
        },
    },
});
</script>

<template>
    <Head>
        <title>Reporting - Admin</title>
    </Head>

    <Layout>
        <top-bar title="Reporting"></top-bar>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <!-- Total Users Card -->
            <div class="bg-white p-6 rounded-lg shadow-md text-center">
                <h2 class="text-xl font-semibold mb-2">Total Users Registered</h2>
                <p class="text-4xl font-bold text-green-500">{{ totalUsers }}</p>
            </div>

            <!-- Users Registered Today Card -->
            <div class="bg-white p-6 rounded-lg shadow-md text-center">
                <h2 class="text-xl font-semibold mb-2">Users Registered Today</h2>
                <p class="text-4xl font-bold text-blue-500">{{ usersRegisteredToday }}</p>
            </div>

            <!-- Total Subscription Sales -->
            <div class="bg-white p-6 rounded-lg shadow-md text-center">
                <h2 class="text-xl font-semibold mb-2">Total Subscription Sales</h2>
                <p class="text-4xl font-bold text-purple-500">{{ subscriptionSales.reduce((acc, sale) => acc + sale, 0) }}</p>
            </div>
        </div>

        <!-- Subscription Sales Chart -->
        <div class="bg-white p-6 rounded-lg shadow-md">
            <h2 class="text-xl font-bold mb-4">Subscription Sales Overview</h2>
            <div class="relative h-64">
                <Bar :data="chartData" :options="chartOptions" />
            </div>
        </div>
    </Layout>
</template>
