
<script setup>
import Layout from '@/Layouts/Admin.vue'
import { Head } from '@inertiajs/vue3'
import { computed, onMounted } from 'vue';
import TopBar from "@/Components/Admin/TopBar.vue";
import Pagination from "@/Components/Admin/Pagination.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
    users: Object
});

onMounted(() => {

});

function deleteUser(user) {
    if (confirm('Are you sure you want to delete this user?')) {
        axios.delete(`/admin/users/${user.id}`)
            .then(response => {
                alert('User deleted successfully');
            })
            .catch(error => {
                alert('An error occurred while deleting the user');
            });
    }
}

function blockUser(user) {
    if (confirm('Are you sure you want to block this user?')) {
        axios.patch(`/admin/users/block/${user.id}`)
            .then(response => {
                alert('User blocked successfully');
            })
            .catch(error => {
                alert('An error occurred while blocking the user');
            });
    }
}

function unblockUser(user) {
    if (confirm('Are you sure you want to unblock this user?')) {
        axios.patch(`/admin/users/unblock/${user.id}`)
            .then(response => {
                alert('User unblocked successfully');
            })
            .catch(error => {
                alert('An error occurred while unblocking the user');
            });
    }
}

function cancelSubscription(user) {
    if (confirm('Are you sure you want to cancel this user\'s subscription?')) {
        axios.patch(`/admin/users/cancel-subscription/${user.id}`)
            .then(response => {
                alert('Subscription canceled successfully');
            })
            .catch(error => {
               alert('An error occurred while canceling the subscription');
            });
    }
}

</script>

<template>
    <Head>
        <title>Manage Users - Yoclo</title>
    </Head>

    <Layout>
        <top-bar title="Manage Users"></top-bar>

        <table class="min-w-full divide-y divide-gray-300">
            <thead>
                <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Account type</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Username</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Registration date</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
                <tr v-for="user in users.data">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 capitalize">
                        <span
                            :class="{
      'bg-blue-100 text-blue-800': user.account_type.toLowerCase() === 'admin',
      'bg-green-100 text-green-800': user.account_type.toLowerCase() === 'user',
      'bg-red-100 text-red-800': user.account_type.toLowerCase() === 'brand',
      'bg-purple-100 text-purple-800': user.account_type.toLowerCase() === 'influencer',
      'bg-amber-100 text-amber-800': user.account_type.toLowerCase() === 'creator',
      'bg-cyan-100 text-cyan-800': user.account_type.toLowerCase() === 'agency',
      'bg-yellow-100 text-yellow-800': user.account_type.toLowerCase() === 'guest',
      // Add more account types and their corresponding classes here
    }"
                            class="inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-semibold capitalize"
                        >
    {{ user.account_type }}
  </span>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.name }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><a :href="'mailto:' + user.email">{{ user.email }}</a></td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ new Date(user.created_at).toLocaleDateString() }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span v-if="user.status === 'active'" class="capitalize inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            {{ user.status }}
                        </span>
                        <span v-else-if="user.status  === 'pending'" class="capitalize inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                            {{ user.status }}
                        </span>
                        <span v-else class="capitalize inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Inactive</span>

                        <span class="ml-2 bg-green-100 text-green-800 capitalize inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ring-green-600/20" v-if="user.stripe_subscription_id">Subscribed</span>
                        <span class="ml-2 bg-cyan-100 text-cyan-800 capitalize inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ring-green-600/20" v-if="!user.stripe_subscription_id">No Subscription</span>

                        <span class="ml-2 bg-red-100 text-red-800 capitalize inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ring-green-600/20" v-if="user.blocked">Blocked</span>
                        <span class="ml-2 bg-green-100 text-green-800 capitalize inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ring-green-600/20" v-if="!user.blocked">Not Blocked</span>
                    </td>
                    <td>
                        <Menu as="div" class="relative inline-block text-left mr-2">
                            <div>
                                <MenuButton class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                    <svg height="15" enable-background="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" id="fi_17498275"><g id="grid"></g><g id="Layer_2"></g><g id="Layer_3"></g><g id="Layer_4"></g><g id="Layer_5"></g><g id="Layer_6"></g><g id="Layer_7"></g><g id="Layer_8"></g><g id="Layer_9"></g><g id="Layer_10"></g><g id="Layer_11"></g><g id="Layer_12"></g><g id="Layer_13"></g><g id="Layer_14"></g><g id="Layer_15"></g><g id="Layer_16"></g><g id="Layer_17"></g><g id="Layer_18"></g><g id="Layer_19"></g><g id="Layer_20"></g><g id="Layer_21"></g><g id="Layer_22"></g><g id="Layer_23"></g><g id="Layer_24"></g><g id="Layer_25"></g><g id="Layer_26"></g><g id="Layer_27"></g><g id="Layer_28"></g><g id="Layer_29"></g><g id="Layer_30"></g><g id="Layer_31"></g><g id="Layer_32"></g><g id="Layer_33"></g><g id="Layer_34"></g><g id="Layer_35"></g><g id="Layer_36"></g><g id="Layer_37"></g><g id="Layer_38"></g><g id="Layer_39"></g><g id="Layer_40"></g><g id="Layer_41"></g><g id="Layer_42"></g><g id="Layer_43"></g><g id="Layer_44"></g><g id="Layer_45"></g><g id="Layer_46"></g><g id="Layer_47"></g><g id="Layer_48"></g><g id="Layer_49"><circle cx="16" cy="6" r="3"></circle><circle cx="16" cy="16" r="3"></circle><circle cx="16" cy="26" r="3"></circle></g><g id="Layer_50"></g><g id="Layer_51"></g><g id="Layer_52"></g><g id="Layer_53"></g><g id="Layer_54"></g><g id="Layer_55"></g><g id="Layer_56"></g><g id="Layer_57"></g><g id="Layer_58"></g><g id="Layer_59"></g><g id="Layer_60"></g><g id="Layer_61"></g></svg>
                                </MenuButton>
                            </div>

                            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div class="py-1">
                                        <MenuItem v-if="user.stripe_subscription_id" v-slot="{ active }">
                                            <a href="#" @click="cancelSubscription(user)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Cancel subscription</a>
                                        </MenuItem>
                                        <MenuItem v-if="!user.blocked" v-slot="{ active }">
                                            <a href="#" @click="blockUser(user)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Block Account</a>
                                        </MenuItem>
                                        <MenuItem v-if="user.blocked" v-slot="{ active }">
                                            <a href="#" @click="unblockUser(user)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Block Account</a>
                                        </MenuItem>
                                        <MenuItem v-slot="{ active }">
                                            <a :href="'/admin/users/edit/' + user.id" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">View</a>
                                        </MenuItem>
                                        <MenuItem v-slot="{ active }">
                                            <a href="#" @click="deleteUser(user)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Delete</a>
                                        </MenuItem>
                                    </div>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </td>
                </tr>
            </tbody>
        </table>

        <pagination :data="users"></pagination>
    </Layout>
</template>
