
<script setup>
import Layout from '../Layouts/Default.vue'
import { Head } from '@inertiajs/vue3'
</script>

<template>
    <Head>
        <title>Register - Yoclo</title>
    </Head>

    <Layout>
        <register-form></register-form>

        <footer-component></footer-component>
    </Layout>
</template>

<style lang="scss" scoped>

</style>

<script>
import RegisterForm from "../Components/RegisterForm.vue";
import FooterComponent from "../Components/Footer.vue";
export default {
    components: {
        RegisterForm,
        FooterComponent
    },
    setup() {
        return {

        }
    },
    data: () => ({

    }),
    methods: {

    },
    mounted() {

    }
}

</script>
