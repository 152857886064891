<script setup>
import Layout from '../Layouts/SplitForm.vue'
import { Head } from '@inertiajs/vue3'
import {usePage} from "@inertiajs/vue3";
import SplitForm from "@/Layouts/SplitForm.vue";
const page = usePage();
const user = page.props.user;

</script>

<template>
    <Head>
        <title>Subscription confirmed</title>
</Head>

    <SplitForm title="Thank you for signing up!">
        <p class="mb-4">Congratulations, [Brand Name] Your payment has been received, and your plan is now active.</p>

        <p class="mb-4">To complete your registration, please:</p>

        <ul class="mb-4">
            <li><strong>Check Your Email</strong>: Look for a confirmation link in your inbox..</li>
        </ul>

        <strong class="mb-4">Important</strong>:

        <ul class="mb-4">
            <li>If you don't see the email, check your spam or junk folder.</li>
            <li>Need help? Contact our support team at <a href="mailto:hello@yoclo.co.uk">hello@yoclo.co.uk</a></li>
        </ul>

        <p>We're excited to have you on board and look forward to helping you connect with amazing creators.</p>
    </SplitForm>
</template>
