
<script setup>
import Layout from '../Layouts/Default.vue'
import { Head } from '@inertiajs/vue3'
</script>

<template>
    <Head>
        <title>Sitemap - Yoclo</title>
        <meta name="description" content="Explore the sitemap of PolyXMedia to easily navigate through our comprehensive suite of web development, SEO, and digital marketing services.">
    </Head>

    <Layout>
        <h2>Sitemap</h2>

        <ul class="sitemap">
            <li><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/blog">Blog</a></li>
            <li><a href="/terms-and-conditions">Terms &amp; Conditions</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/sitemap">Sitemap</a></li>
        </ul>
    </Layout>
</template>

<style lang="scss">
    .sitemap {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            margin-bottom: 1rem;

            a {
                color: #000;
                text-decoration: underline;

                &:hover {
                    color: #000;
                    text-decoration: none;
                }
            }
            ul {
                padding-left: 3rem;
                padding-top: 1rem;
                li {
                    margin-bottom: 1rem;
                    a {
                        color: #000;
                        text-decoration: underline;

                        &:hover {
                            color: #000;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
</style>
