<script setup>
import { usePage } from '@inertiajs/vue3';
import {nextTick, onMounted, onUnmounted, ref} from 'vue';
import SvgIcon from "@/Components/SvgIcon.vue";

// Define the props
const props = defineProps({
    title: {
        type: String,
        default: '', // Default value if title is not passed
    },
});

// Get shared data using usePage()
const { props: pageProps } = usePage();

// Ref to control the dropdown visibility
const showDropdown = ref(false);

// Function to toggle the dropdown visibility
const toggleDropdown = () => {
    showDropdown.value = !showDropdown.value;
};

const closeDropdown = (event) => {
    // Check if the clicked element is the dropdown or the avatar button
    const dropdown = document.getElementById('dropdown-menu');
    const avatarButton = document.getElementById('avatar-button');

    // If the click target is not the dropdown or avatar button, close the dropdown
    if (!dropdown.contains(event.target) && !avatarButton.contains(event.target)) {
        showDropdown.value = false;
    }
};

// Attach and detach the event listener for "click away"
onMounted(() => {
    document.addEventListener('mousedown', closeDropdown);
});

onUnmounted(() => {
    document.removeEventListener('mousedown', closeDropdown);
});

function logout() {
    axios.post('/logout').then(() => {
        window.location = '/';
    });
}

</script>
<template>
    <div>
        <div class="flex justify-between mb-6">
            <div>
                <h1 class="text-2xl font-bold">{{ title ?? 'Dashboard'}}</h1>
            </div>   
        </div>
    </div>
</template>
