<script setup>
import { ref, watch } from 'vue';
import Navigation from "@/Components/Navigation.vue";
import FooterComponent from "@/Components/Footer.vue";
import { usePage } from '@inertiajs/vue3';

const page = usePage();

</script>

<template>
    <main class="bg-black dark text-white antialiased">
        <navigation />
        <div class="pt-[93px] md:pt-[157px]">
            <slot />
        </div>
        <footer-component></footer-component>
    </main>
</template>

<style lang="scss">

</style>

<script>

export default {
    components: {

    },
    data() {
        return {

        }
    },
    methods: {

    },
    mounted() {

    }
}
</script>
