<script setup>
import Authed from "@/Layouts/Authed.vue";
import Button from '@/Components/Forms/InputButton.vue';
import { usePage } from "@inertiajs/vue3";
import InputTab from "@/Components/Forms/InputTab.vue";
import {ref} from "vue";

const user = usePage().props.auth.user;

const activeTab = ref('creators');
const setActiveTab = (tab) => {
    activeTab.value = tab;
}

</script>

<template>

    <Authed>

        <div class="container">
            <h1 class="text-6xl">{{ user.first_name}}</h1>
            <!-- Replace with agency name -->
            <p class="mb-8">Part of world 84</p>

            <p class="mb-4">Key metrics</p>


            <div class="grid grid-cols-4 gap-3 h-[200px]">
                <div class="card flex justify-end bg-gray-800 shadow-lg rounded-[8px] p-4">
                    <span>Total number of creators</span>
                    <strong>58</strong>
                    <Button>View all Creators</Button>
                </div>
                <div class="card flex justify-center bg-gray-800 shadow-lg rounded-[8px] p-4">
                    <span>Total creator following</span>
                    <strong>10,508, 974</strong>
                </div>

                <div class="col-span-2 flex justify-end bg-gray-800 shadow-lg rounded-[8px] p-4">
                    <div class="grid grid-cols-2">

                    </div>
                </div>
            </div>


            <nav class="-mb-px flex space-x-8 mb-12" aria-label="Tabs">
                <input-tab
                    :active="activeTab === 'requiresAttention'"
                    @click="setActiveTab('requiresAttention')"
                    label="Requires attention"
                />
                <input-tab
                    :active="activeTab === 'creators'"
                    @click="setActiveTab('creators')"
                    label="Your creators"
                />
                <input-tab
                    :active="activeTab === 'team'"
                    @click="setActiveTab('team')"
                    label="Your team"
                />
            </nav>


            <section class="py-8">
                <div class="flex flex-row">
                    <div class="flex flex-col me-4">
                        <label for="" class="mb-2">Location</label>
                        <select name="" id="" class="form-select cursor-pointer border rounded-[8px] border-white bg-transparent text-white w-[180px]">
                            <option value="">Select</option>
                        </select>
                    </div>
                    <div class="flex flex-col me-4">
                        <label for="" class="mb-2">Primary social</label>
                        <select name="" id="" class="form-select cursor-pointer border rounded-[8px] border-white bg-transparent text-white w-[180px]">
                            <option value="">Select</option>
                        </select>
                    </div>
                    <div class="flex flex-col me-4">
                        <label for="" class="mb-2">Alert</label>
                        <select name="" id="" class="form-select cursor-pointer border rounded-[8px] border-white bg-transparent text-white w-[180px]">
                            <option value="">Select</option>
                        </select>
                    </div>
                </div>

                <div role="tabpanel" v-if="activeTab === 'requiresAttention'">
                    Content that requires attention
                </div>

                <div role="tabpanel" v-if="activeTab === 'creators'">
                    Content that lists creators
                </div>

                <div role="tabpanel" v-if="activeTab === 'team'">
                    Content that is related to your team
                </div>
            </section>
        </div>

    </Authed>

</template>
