<script setup>
import {usePage} from "@inertiajs/vue3";
const page = usePage();
const user = page.props.auth.user;

// Format the follower count
const formatFollowers = (followers) => {
    if (followers > 1000000) {
        return (followers / 1000000).toFixed(1) + 'm Followers';
    }
    if (followers > 1000) {
        return (followers / 1000).toFixed(1) + 'k Followers';
    }
    return followers + ' Followers';
}
</script>
<template>
    <ul>
        <!-- Facebook -->
        <li v-if="user.facebook_token" class="inline-flex flex-col text-center me-4">
            <social-icon platform="facebook" classes="mb-2.5 h-12 w-12" />
            {{ formatFollowers(user.facebook_followers) }}
        </li>

        <!-- Instagram -->
        <li v-if="user.instagram_token" class="inline-flex flex-col text-center me-4">
            <social-icon platform="instagram" classes="mb-2.5 h-12 w-12" />
            {{ formatFollowers(user.instagram_followers) }}
        </li>

        <!-- YouTube -->
        <li v-if="user.youtube_token" class="inline-flex flex-col text-center me-4">
            <social-icon platform="youtube" classes="mb-2.5 h-12 w-12" />
            {{ formatFollowers(user.youtube_followers) }}
        </li>

        <!-- Twitter (X) -->
        <li v-if="user.twitter_token" class="inline-flex flex-col text-center me-4">
            <social-icon platform="x" classes="mb-2.5 h-12 w-12" />
            {{ formatFollowers(user.twitter_followers) }}
        </li>

        <!-- TikTok -->
        <li v-if="user.tiktok_token" class="inline-flex flex-col text-center me-4">
            <social-icon platform="tiktok" classes="mb-2.5 h-12 w-12" />
            {{ formatFollowers(user.tiktok_followers) }}
        </li>
    </ul>
</template>
