
<script setup>
import Layout from '@/Layouts/Admin.vue'
import { Head } from '@inertiajs/vue3'
import TopBar from "@/Components/Admin/TopBar.vue";

const props = defineProps({
    users: Object
});

import { onMounted } from 'vue';
import Pagination from "@/Components/Admin/Pagination.vue";

onMounted(() => {

});

const stats = [
  { name: 'Basic', value: '$405,091.00', change: '+4.75%', changeType: 'positive' },
  { name: 'Advanced', value: '$12,787.00', change: '+54.02%', changeType: 'negative' },
  { name: 'Enterprise', value: '$245,988.00', change: '-1.39%', changeType: 'positive' },
];

</script>

<template>
    <Head>
        <title>Subscriptions - Yoclo</title>
    </Head>

    <Layout>
        <top-bar title="Subscriptions"></top-bar>

        <dl class="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-3">
            <div v-for="stat in stats" :key="stat.name" class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
                <dt class="text-sm font-medium leading-6 text-gray-500">{{ stat.name }}</dt>
                <dd :class="[stat.changeType === 'negative' ? 'text-rose-600' : 'text-gray-700', 'text-xs font-medium']">{{ stat.change }}</dd>
                <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">{{ stat.value }}</dd>
            </div>
        </dl>

        <table class="min-w-full divide-y divide-gray-300">
            <thead>
                <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Username</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Registration date</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
                <tr v-for="user in users.data">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{{ user.name }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.email }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ new Date(user.created_at).toLocaleDateString()  }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span v-if="user.status === 'active'" class="capitalize inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            {{ user.status }}
                        </span>
                        <span v-else-if="user.status  === 'pending'" class="capitalize inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                            {{ user.status }}
                        </span>
                        <span v-else class="capitalize inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Inactive</span>

                    </td>
                </tr>
            </tbody>
        </table>


        <pagination :data="users"></pagination>
    </Layout>
</template>
