<script setup>

import Layout from "@/Layouts/Default.vue";
import SplitForm from "@/Layouts/SplitForm.vue";
import InputText from "@/Components/Forms/InputText.vue";
import InputLabel from "@/Components/Forms/InputLabel.vue";
import Modal from "@/Components/Layout/Modal.vue";
import InputButton from "@/Components/Forms/InputButton.vue";
import {ref} from "vue";

const modalOpen = ref(false);

const openModal = () => {
    modalOpen.value = true
};

</script>

<template>
    <Layout>

        <SplitForm title="Let's get started">
            <form>
                <div class="mb-6">
                    <InputLabel htmlFor="password" value="Password"/>
                    <input-text
                        id="password" class="block mt-1 w-full"
                        name="password"
                        required
                        autocomplete="new-password"
                        placeholder="Enter your password"
                        type="password"
                    />
                </div>

                <div class="mb-6">
                    <InputLabel htmlFor="passwordConfirm" value="Confirm password "/>
                    <InputText
                        id="passwordConfirm" class="block mt-1 w-full"
                        name="passwordConfirm"
                        required
                        autocomplete="new-password"
                        placeholder="Confirm your password"
                    />
                </div>

                <input-button @click="openModal">Set password and continue registration</input-button>
            </form>

            <Modal :model-value="modalOpen">

                <h2 class="text-4xl mb-5">
                    Registration Successful 🎉🎉🎉
                </h2>

                <p class="mb-4">
                    Welcome to Yoclo!
                </p>
                <p class="mb-4">
                    Great to have you here!
                </p>
                <p class="mb-4">
                    [Agency Name] has started your profile on Yoclo with some initial details.
                    Your next step? Bringing it to life.
                </p>

                <p class="mb-4">
                    Completing Your Profile: It's easy and impactful. The most crucial action you
                    can take now is connecting your social media accounts. Here's why it matters:
                </p>

                <ul class="list-disc list-inside">
                    <li>
                        Direct Showcase: Instantly share your vibrant content and personality with potential brand partners.
                    </li>
                    <li>
                        Simplified Updates: Your profile stats auto-update, showcasing your growing influence without extra work.
                    </li>
                    <li>
                        Enhanced Matchmaking: Brands see your true style and reach, making for perfect matches.
                    </li>
                </ul>

                <p class="mb-4">
                    Think of it as setting the stage for your digital presence on Yoclo. [Agency Name] set
                    the foundation; now, add your flair to attract the best collaborations.
                </p>

                <p class="mb-10">
                    If you have questions or need a hand, reach out. We're excited to see the connections you'll create!
                </p>

                <input-button href="/profile">
                    Continue to finish setting up your Creator profile
                </input-button>
            </Modal>

        </SplitForm>
    </Layout>
</template>
