<script setup>
import { usePage, useForm } from '@inertiajs/vue3'

import Layout from "@/Layouts/Default.vue";
import SplitForm from "@/Layouts/SplitForm.vue";
import InputLabel from "@/Components/Forms/InputLabel.vue";
import InputText from "@/Components/Forms/InputText.vue";
import InputButton from "@/Components/Forms/InputButton.vue";

const form = useForm({
    password: null,
    passwordConfirmation: null,
})

// Access the props, including the email
const { props } = usePage()
const email = props.user.email;
const resetToken = props.resetToken;

function changePassword() {
    form.post('/change-password/' + resetToken, form)
}
</script>

<template>

    <Layout>
        <SplitForm title="Reset your password">
            <p class="text-white mb-4">Please enter your new password below. Make sure it’s strong and secure.</p>

            <!-- Display success message if available -->
            <div v-if="props.flash.success" class="text-green-500">
                {{ props.flash.success }}
            </div>

            <!-- Display general error message -->
            <div v-if="form.errors.error" class="text-red-500 text-sm mt-1 mb-3">
                {{ form.errors.error }}
            </div>

            <form @submit.prevent="changePassword">
                <!-- Email Address -->
                <div>
                    <InputLabel for="email" value="Email"/>
                    <InputText
                        id="email"
                        class="block mt-1 w-full"
                        type="email"
                        name="email"
                        :value="email"
                        required
                        autofocus
                        disabled="disabled"
                        autocomplete="username"
                    />
                </div>

                <!-- Password -->
                <div class="mt-4">
                    <InputLabel for="password" value="Password"/>
                    <InputText
                        id="password"
                        v-model="form.password"
                        class="block mt-1 w-full"
                        type="password"
                        name="password"
                        required
                        autocomplete="new-password"
                    />
                </div>

                <!-- Confirm Password -->
                <div class="mt-4 mb-10">
                    <InputLabel for="password_confirmation" value="Confirm you password"/>

                    <InputText
                        id="password_confirmation"
                        v-model="form.passwordConfirmation"
                        class="block mt-1 w-full"
                        type="password"
                        name="password_confirmation"
                        required
                        autocomplete="new-password"
                    />

                </div>

                <input-button type="submit">Reset password</input-button>
            </form>

        </SplitForm>
    </Layout>

</template>
