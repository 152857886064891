<script setup>
import {useForm, usePage} from '@inertiajs/vue3'
import InputText from "@/Components/Forms/InputText.vue";
import InputLabel from "@/Components/Forms/InputLabel.vue";
import InputCheckbox from "@/Components/Forms/InputCheckbox.vue";
import InputTextArea from "@/Components/Forms/InputTextArea.vue";
import InputButton from "@/Components/Forms/InputButton.vue";
import ConnectedAccounts from "@/Components/Yoclo/ConnectedAccounts.vue";
import ImageUploadWithCrop from "@/Components/ImageUploadWithCrop.vue";
import { marketTagOptions } from "@/data/marketTagOptions.js";
import {Inertia} from "@inertiajs/inertia";
import Multiselect from 'vue-multiselect'

const page = usePage();
const user = page.props.auth.user;

const form = useForm({
    biography: user.biography || null,
    show_alias: user.show_alias || false,
    professional_alias: user.professional_alias || null,
    first_name: user.first_name || null,
    last_name: user.last_name || null,
    location: user.location || null,
    email: user.email || null,
    password: null,  // Leave password empty for user to fill in manually
    remember: false,
    market_tags: user.market_tags || null,
    profile_picture: user.profile_picture || null, // Assuming user may upload a profile picture
    cover_photo: user.cover_photo || null // Assuming user may upload a profile picture
});


const updateDetails = () => {
    form.post('/user/update', {
        onSuccess: () => {
            // Refresh the page data after the form submission is successful
            Inertia.reload({
                only: ['user'],  // Replace with the specific data you want to reload, or remove 'only' to reload everything
                preserveScroll: true, // Preserve scroll position
                preserveState: false,  // Reset the form state
            });
        }
    });
};

</script>

<template>
    <div class="relative overflow-y-auto max-h-full md:pe-20">
        <div class="flex mb-16 justify-between">
            <h5 class="font-regular text-4xl text-gray-800 dark:text-white leading-tight tracking-tighter">
                Update your Creator page
            </h5>
        </div>
        <form class="form-content h-full" @submit.prevent="updateDetails">
            <div class="grid gap-6 mb-14">
                <div class="max-w-md">
                    <input-label for="first_name" value="First name"/>
                    <input-text
                        v-model="form.first_name"
                        id="first_name"
                        name="first_name"
                        type="text"
                        class="mt-1 block w-full"
                        autocomplete="first_name"
                    />
                    <div v-if="form.errors.first_name" class="text-red-500 text-sm mt-1">
                        {{ form.errors.first_name }}
                    </div>
                </div>
                <div class="max-w-md">
                    <input-label for="last_name" value="Last name"/>
                    <input-text
                        v-model="form.last_name"
                        id="last_name"
                        name="last_name"
                        type="text"
                        class="mt-1 block w-full"
                        autocomplete="last_name"
                    />

                    <div v-if="form.errors.last_name" class="text-red-500 text-sm mt-1">
                        {{ form.errors.last_name }}
                    </div>
                </div>
                <div class="max-w-md">
                    <input-label for="location" value="Location"/>
                    <input-text
                        v-model="form.location"
                        id="location"
                        name="location"
                        type="text"
                        class="mt-1 block w-full"
                        autocomplete="location"
                    />

                    <div v-if="form.errors.last_name" class="text-red-500 text-sm mt-1">
                        {{ form.errors.last_name }}
                    </div>
                </div>

                <div class="flex flex-wrap md:flex-row">
                    <div class="flex-1 max-w-md">
                        <input-label for="professional_alias" value="Professional Alias (Optional)"/>
                        <input-text
                            v-model="form.professional_alias"
                            id="professional_alias"
                            name="professional_alias"
                            type="text"
                            class="mt-1 block w-full"
                            autocomplete="professional_alias"
                        />

                        <div v-if="form.errors.professional_alias" class="text-red-500 text-sm mt-1">
                            {{ form.errors.professional_alias }}
                        </div>
                    </div>

                    <div class="flex items-center md:ms-6 mt-6">
                        <input-checkbox
                            v-model="form.show_alias"
                            id="show_alias"
                            name="show_alias"
                        />
                        <input-label for="show_alias" class="max-w-60"
                                     value="Show Professional Alias on profile instead of First and Last name"/>
                    </div>

                    <div v-if="form.errors.show_alias" class="text-red-500 text-sm mt-1">
                        {{ form.errors.show_alias }}
                    </div>
                </div>

                <div class="mb-4">
                    <h4 class="mb-2">Profile Picture</h4>
                    <image-upload-with-crop
                        :default-image="form.profile_picture"
                        aspect-ratio="1"
                        :crop-box-resizable="true"
                        :crop-box-width="200"
                        :crop-box-height="200"
                        crop-classes="w-[128px] h-[128px] rounded-full"
                        v-model="form.profile_picture"/>

                    <div v-if="form.errors.profile_picture" class="text-red-500 text-sm mt-1">
                        {{ form.errors.profile_picture }}
                    </div>
                </div>

                <div class="max-w-md">
                    <h4 class="mb-2">Profile Banner</h4>
                    <image-upload-with-crop
                        aspect-ratio="5/2"
                        :crop-box-resizable="true"
                        crop-box-width="100%"
                        crop-box-height="200"
                        :default-image="form.cover_photo"
                        v-model="form.cover_photo"
                        crop-classes="w-full h-[226px] rounded-md"
                    />

                    <div v-if="form.errors.cover_photo" class="text-red-500 text-sm mt-1">
                        {{ form.errors.cover_photo }}
                    </div>
                </div>
                <div>
                    <input-label for="biography" value="Biography"/>
                    <input-text-area
                        v-model="form.biography"
                        rows="5"
                        id="biography"
                        name="biography"
                        type="text"
                        class="mt-1 block w-full"
                        autocomplete="biography"
                    />

                    <div v-if="form.errors.biography" class="text-red-500 text-sm mt-1">
                        {{ form.errors.biography }}
                    </div>
                </div>

                <div>
                    <input-label for="tags" value="Add market tags (maximum 3)" class="mb-2"/>
                    <multiselect
                        :show-labels="false"
                        :close-on-select="false"
                        :multiple="true"
                        :options="marketTagOptions"
                        track-by="id"
                        label="name"
                        :max="3"
                        v-model="form.market_tags"
                    />
                </div>
            </div>

            <div class="lg:col-span-2 prose text-white mb-6">
                <h6 class="text-4xl font-regular">Link social accounts</h6>
                <p>
                    Enhance Your Profile - Connect Your Social Media:
                </p>

                <ul class="marker:text-white">
                    <li>Automatic Updates: Keep your stats and engagement data fresh with no extra effort.</li>
                    <li>Boost Credibility: Show verified, real-time insights to brands.</li>
                    <li>Secure & Private: We never store your login details and only access public profile
                        information.
                    </li>
                </ul>
                <p>Connect in a click, and stay in control – you can disconnect anytime.</p>
            </div>

            <connected-accounts></connected-accounts>

            <div class="lg:col-span-2 space-y-12p-4 mt-10">
                <div class="flex justify-end">
                    <button class="text-white me-6 underline">I'll connect my social accounts later</button>
                    <input-button type="submit" :disabled="form.processing">
                        {{ form.processing ? 'Saving...' : 'Save' }}
                    </input-button>
                </div>
            </div>
        </form>
    </div>
</template>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
