export default {
    beforeMount(el, binding) {
        el.clickOutsideEvent = function (event) {
            // Check if the clicked element is outside the component
            if (!(el === event.target || el.contains(event.target))) {
                // Call the provided method in the directive's value
                binding.value(event);
            }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
    },
};
