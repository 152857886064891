
<script setup>
import Layout from '../Layouts/Default.vue'
import { Head } from '@inertiajs/vue3'
</script>

<template>
    <Head>
        <title>Terms and Conditions - Yoclo</title>
        <meta name="description" content="">
    </Head>

    <Layout>
        <h1>Terms &amp; Conditions</h1>
    </Layout>
</template>

<style lang="scss" scoped>
</style>
