<script setup>

import {usePage} from "@inertiajs/vue3";
import InputButton from "@/Components/Forms/InputButton.vue";
import Layout from "@/Layouts/Authed.vue";
import SocialCounts from "@/Components/Yoclo/SocialCounts.vue";
import Badge from "@/Components/Layout/Badge.vue";
import InputTab from "@/Components/Forms/InputTab.vue";
import SocialIcon from "@/Components/Yoclo/SocialIcon.vue";

const page = usePage();

const { profileUser } = page.props;

</script>

<template>
    <Layout>
        <div
            v-if="profileUser.is_bespoke_image"
            class="absolute bg-cover bg-center bg-no-repeat h-full w-full md:w-[80%] inset-0 md:left-[20%] z-0 max-h-[60vh]"
        >
        </div>
        <div v-if="!profileUser.is_bespoke_image" class="relative">
            <div class="bg-cover bg-center bg-no-repeat w-full h-[526px] inset-0 z-0 max-h-[60vh]"
                 :style="{ 'background-image': `url(${profileUser.cover_photo})`}">

                <div class="relative z-20 text-white">
                    <div class="absolute top-0 pe-20 h-[70px] w-full flex items-center justify-end"
                         style="background: rgba(43, 47, 50, 0.30); backdrop-filter: blur(32.40px)">
                        <input-button @click="showModal">Update Profile Page</input-button>
                    </div>

                    <div class="container pt-20">
                        <Badge icon="pin" v-if="profileUser.location">{{ profileUser.location }}</Badge>
                        <div class="spacer inline-block mx-3" v-if="profileUser.location">|</div>

                        <Badge v-for="(tag, index) in profileUser.market_tags" class="ms-2">{{ tag.name }}</Badge>

                        <h1 class="text-7xl font-bold mt-6" v-if="!profileUser.professional_alias || !profileUser.show_alias">
                            {{ profileUser.first_name }} {{ profileUser.last_name }}</h1>
                        <h1 class="text-7xl font-bold mt-6" v-if="profileUser.professional_alias && profileUser.show_alias">
                            {{ profileUser.professional_alias }}</h1>
                        <p class="text-2.5xl mb-4">12 Total following</p>
                        <social-counts></social-counts>
                    </div>
                </div>
            </div>
        </div>

        <div class="container pb-20">
            <!-- Tabs Navigation -->
            <nav class="-mb-px flex space-x-8 mb-12" aria-label="Tabs">
                <input-tab
                    :active="activeTab === 'overview'"
                    @click="setActiveTab('overview')"
                    label="Overview"
                />
                <input-tab
                    :active="activeTab === 'latestPosts'"
                    @click="setActiveTab('latestPosts')"
                    label="Latest Posts"
                />
                <input-tab
                    :active="activeTab === 'about'"
                    @click="setActiveTab('about')"
                    label="About"
                />
            </nav>

            <!-- Content for Overview Tab -->
            <div v-if="activeTab === 'overview'">
                Overview
            </div>

            <!-- Content for Latest Posts Tab -->
            <div v-if="activeTab === 'latestPosts'" class="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-12">
                <div v-for="i in 6" :key="i">
                    <social-icon platform="tiktok"/>
                    <span class="inline-block ms-2 mb-2">5 hours ago</span>
                    <div class="shadow-lg rounded-3xl h-[488px] bg-[#171B1F]"></div>
                    <ul class="flex justify-between font-light mt-5 text-white">
                        <li>
                            5000 <strong class="font-medium">Likes</strong>
                        </li>
                        <li>
                            400000 <strong class="font-medium">Comments</strong>
                        </li>
                        <li>
                            100000 <strong class="font-medium">Views</strong>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Content for About Tab -->
            <div v-if="activeTab === 'about'" class="max-w-2xl">
                <strong class="mb-6 block">About me</strong>
                <p>
                    {{ profileUser.biography }}
                </p>
            </div>
        </div>
    </Layout>

</template>
