<template>
    <label :for="htmlFor" :class="computedClass">
        {{ label }}
    </label>
</template>

<script setup>
import {computed} from 'vue'
import {useI18n} from 'vue-i18n'

// Props
const props = defineProps({
    htmlFor: String,  // Change from 'for' to 'htmlFor'
    value: String,
    class: String
})

// Internationalization
const {t} = useI18n()

// Compute label text using i18n if available
const label = computed(() => t(props.value))

// Compute dynamic class (allow custom overrides)
const computedClass = computed(() => {
    return `block font-normal text-sm text-gray-700 dark:text-white ${props.class || ''}`
})
</script>

<style>
/* Default styles (can be overridden by props.class) */
</style>
