<script setup>
import { ref } from 'vue'
import SocialIcon from "@/Components/Yoclo/SocialIcon.vue";
import SvgIcon from "@/Components/SvgIcon.vue";
import {usePage} from "@inertiajs/vue3";

const page = usePage();
const {user} = page.props.auth;

// Props for platform and connection state
const props = defineProps({
    platform: {
        type: String,
        required: true
    },
    username: {
        type: String,
        default: ''
    },
    avatar: {
        type: String,
        default: ''
    }
});

const platformId = ref(null);

platformId.value = user[`${props.platform}_id`];

// Base URL for the Laravel API (assuming it's on the same server)
const baseUrl = '/oauth';

// Methods for connecting and disconnecting accounts
const connect = async () => {
    window.location.href = `${baseUrl}/${props.platform}/redirect`;
};

const disconnect = async () => {
    if (confirm(`Are you sure you want to remove this ${props.platform} account?`)) {
        try {
            const response = await axios.post(`/oauth/${props.platform}/disconnect`);

            // Handle successful disconnection
            console.log(`Disconnected from ${props.platform}`, response.data);

            // Reset platformId, username, and avatar after disconnection
            platformId.value = null;
            props.username = '';
            props.avatar = '';

            alert(`Successfully disconnected from ${props.platform}!`);
        } catch (error) {
            console.error(`Failed to disconnect from ${props.platform}:`, error);
            alert(`Failed to disconnect from ${props.platform}. Please try again.`);
        }
    }
}

const connectOrDisconnect = () => {
    if (platformId.value) {

        disconnect();
    } else {
        connect();
    }
}

const platformUser = user;

console.log(platformUser[props.platform + '_user']);
</script>

<template>
    <div class="bg-gradient-to-r from-[#00F2EA] to-[#7435FF] p-1 rounded-[20px] overflow-hidden">
        <a href="#"
           @click.prevent="connectOrDisconnect()"
           class="relative bg-[#181A1B] flex flex-col justify-between gap-6 text-white py-4 px-5 rounded-[20px]">
            <template v-if="platformId">
                <span>{{ user[platform + '_user'].user.snippet.customUrl }}</span>
                <img :src="user[platform + '_user'].avatar" alt="" class="w-24 h-24 rounded-full border-2 border-current">
                <span class="text-[#93CAFD] text-sm text-center">
                  Disconnect {{ platform.charAt(0).toUpperCase() + platform.slice(1) }}
                </span>
            </template>

            <template v-else>
                <div class="flex items-center">
                    <svg-icon name="plus-circle" class="mb-1" />
                    <span class="text-white ms-2">Add {{ platform.charAt(0).toUpperCase() + platform.slice(1) }} account</span>
                    <!-- Insert social icon based on platform -->
                    <div class="ms-auto">
                        <svg-icon :name="platform" classes="ms-auto"/>
                    </div>
                </div>
            </template>
        </a>
    </div>
</template>

<style scoped>
/* Add any necessary styles here */
</style>
