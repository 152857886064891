
<script setup>
import Layout from '../Layouts/Default.vue'
import { Head } from '@inertiajs/vue3'
import SvgIcon from "@/Components/SvgIcon.vue";

</script>

<template>
    <Head>
        <title>Verify - Yoclo</title>
    </Head>

    <Layout>
        <div class="relative flex flex-col min-h-screen">
            <div class="container my-auto px-9 md:pt-14 md:px-10 md:pb-[72px] max-w-[485px] md:bg-[#181A1B] flex flex-col !text-white sm:rounded-lg relative z-10 md:shadow-2xl">

                <a href="/" class="inline-block mb-9 -ms-1"><svg-icon name="arrow-left"></svg-icon></a>

                <h2 class="text-3xl font-medium mb-6">Thanks for registering.</h2>

                <p>You'll receive and email with a verification link please click it to verify your account.</p>
            </div>
        </div>
    </Layout>
</template>

<style lang="scss" scoped>

</style>
