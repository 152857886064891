<script setup>
import { usePage, useForm } from '@inertiajs/vue3';
import { computed } from 'vue'
import Layout from '../Layouts/Default.vue';
import SubHeader from "@/Components/SubHeader.vue";
import Logo from "@/Components/Logo.vue";
import InputLabel from "@/Components/Forms/InputLabel.vue";
import InputText from "@/Components/Forms/InputText.vue";
import InputButton from "@/Components/Forms/InputButton.vue";

// Get route parameters (e.g., plan and payment schedule)
const page = usePage();
const plan = page.props.plan;
const schedule = page.props.schedule;

// Define the props
const props = defineProps({
    errors: Object
})

// Initialize form with default values and make it reactive
const form = useForm({
    brandName: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    plan: plan,
    schedule: schedule
});

// Create the computed property for generalError
const generalError = computed(() => {
    // Return the first error if it exists
    return props.errors ? props.errors[0] : null
})

// Function to handle form submission
const proceedToPayment = () => {
    console.log('Proceeding to payment with:', form);
    form.post('/create-account');
};
</script>

<template>
    <Head>
        <title>Choose Plan Tier - Yoclo</title>
        <meta name="description" content="">
    </Head>

    <Layout>
        <div class="h-screen pt-16">
            <div class="container pb-20 overflow-y-auto" style="height: calc(100% - 94px)">
                <a href="/login">
                    <logo class="mb-10" />
                </a>
                <sub-header title="Choose a plan that's right for you" />

                <p class="text-2xl">Step 2 of 3</p>
                <h2 class="text-2.5xl mb-16">Fill in your brand details below</h2>
                <h3 class="text-2.5xl mb-16">Selected Plan: {{ form.plan }}</h3>

                <form @submit.prevent="proceedToPayment" class="max-w-md mx-auto">
                    <div v-if="generalError" class="bg-red-100 text-red-800 p-4 mb-6 rounded-md">
                        {{ generalError }}
                    </div>

                    <!-- Brand Name -->
                    <div class="mb-6">
                        <InputLabel html-for="brand-name" value="Brand name" />
                        <InputText
                            v-model="form.brandName"
                            id="brand-name"
                            type="text"
                            required
                            class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            placeholder="Enter your brand name"
                        />
                        <span v-if="form.errors.brandName" class="inline-block mt-2 text-red-600">{{ form.errors.brandName }}</span>
                    </div>

                    <!-- First Name -->
                    <div class="mb-6">
                        <InputLabel html-for="first-name" value="First name" />
                        <InputText
                            v-model="form.firstName"
                            id="first-name"
                            type="text"
                            required
                            class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            placeholder="Enter your first name"
                        />
                        <span v-if="form.errors.firstName" class="inline-block mt-2 text-red-600">{{ form.errors.firstName }}</span>
                    </div>

                    <!-- Last Name -->
                    <div class="mb-6">
                        <InputLabel html-for="last-name" value="Last name" />
                        <InputText
                            v-model="form.lastName"
                            id="last-name"
                            type="text"
                            required
                            class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            placeholder="Enter your last name"
                        />
                        <span v-if="form.errors.lastName" class="inline-block mt-2 text-red-600">{{ form.errors.lastName }}</span>
                    </div>

                    <!-- Email -->
                    <div class="mb-6">
                        <InputLabel html-for="email" value="Email" />
                        <InputText
                            v-model="form.email"
                            id="email"
                            type="email"
                            required
                            class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            placeholder="Enter your email"
                        />
                        <span v-if="form.errors.email" class="inline-block mt-2 text-red-600">{{ form.errors.email }}</span>
                    </div>

                    <!-- Password -->
                    <div class="mb-6">
                        <InputLabel html-for="password" value="Password" />
                        <InputText
                            v-model="form.password"
                            id="password"
                            type="password"
                            required
                            class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            placeholder="Enter your password"
                        />
                        <span v-if="form.errors.password" class="inline-block mt-2 text-red-600">{{ form.errors.password }}</span>
                    </div>

                    <p class="leading-6 mb-6">
                        By continuing to register an account you agree to the
                        <a href="#" class="text-blue-600 underline">Terms and Conditions</a>
                    </p>

                    <!-- Submit Button -->
                    <input-button type="submit">Proceed to payment</input-button>
                </form>
            </div>
        </div>
    </Layout>
</template>
