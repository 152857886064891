<script setup>
import { ref, watch, onBeforeUnmount, nextTick } from 'vue';
import InputButton from "@/Components/Forms/InputButton.vue";
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { Camera } from '@iconoir/vue';

// Props
const props = defineProps({
    modelValue: String,  // For v-model binding
    defaultImage: String, // Default image URL for the preview
    aspectRatio: {
        type: Number,
        default: 1 // Default to 1:1 (square), but configurable from outside
    },
    cropBoxResizable: {
        type: Boolean,
        default: true  // Allow resizing the crop box by default, configurable from outside
    },
    cropBoxWidth: {
        type: Number,
        default: 200  // Default crop box width, configurable
    },
    cropBoxHeight: {
        type: Number,
        default: 200  // Default crop box height, configurable
    },
    borderRadius: {
        type: String,
        default: '50%'  // Default to rounded (for profile pictures), can be '0' for banners
    },
    cropClasses: {
        type: String,
        default: ''  // Default classes for the image element
    }
});

// Emits
const emit = defineEmits(['update:modelValue']);

// Refs
const imageFile = ref(null);
const imageUrl = ref(null);  // Image URL for the preview
const croppedImageUrl = ref(null);  // Cropped image URL
const cropperInstance = ref(null);
const imageRef = ref(null);
const showCropper = ref(false);  // Controls whether to show the cropper

// Watch for file changes and initialize cropper
watch(imageFile, (newFile) => {
    if (newFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
            imageUrl.value = e.target.result;
            showCropper.value = true;  // Show cropper once the image is selected
        };
        reader.readAsDataURL(newFile);
    } else {
        resetCropper();
    }
});

// Function to handle image load and initialize cropper
// Function to handle image load and initialize cropper
const onImageLoad = () => {
    nextTick(() => {
        if (cropperInstance.value) {
            cropperInstance.value.destroy();  // Destroy any existing cropper instance
        }
        cropperInstance.value = new Cropper(imageRef.value, {
            aspectRatio: props.aspectRatio,  // Use the configurable aspect ratio
            viewMode: 1,
            scalable: true,
            zoomable: true,
            cropBoxResizable: props.cropBoxResizable,  // Use the configurable crop box resizability
            ready() {
                // Set a configurable crop box size
                cropperInstance.value.setCropBoxData({
                    width: props.cropBoxWidth,
                    height: props.cropBoxHeight,
                });
            }
        });
    });
};

// Cleanup cropper on component unmount
onBeforeUnmount(() => {
    resetCropper();
});

// Function to handle file selection
const selectImage = (event) => {
    imageFile.value = event.target.files[0];
};

// Function to confirm cropping and get the cropped image
const confirmCrop = () => {
    if (cropperInstance.value) {
        croppedImageUrl.value = cropperInstance.value.getCroppedCanvas().toDataURL(); // Get the cropped image as Base64

        // Emit the cropped image so it can be saved when submitting the form
        emit('update:modelValue', croppedImageUrl.value);

        // Keep the cropped image for preview but destroy the cropper
        showCropper.value = false;
        cropperInstance.value.destroy();
    }
};

// Function to reset cropper and clear data
const resetCropper = () => {
    if (cropperInstance.value) {
        cropperInstance.value.destroy();
        cropperInstance.value = null;
    }
    imageFile.value = null;
    imageUrl.value = null;
    croppedImageUrl.value = null;
    showCropper.value = false;
};

// Function to cancel cropping
const cancelCrop = () => {
    resetCropper();
};
</script>

<template>
    <div>
        <!-- Show selected file name -->
        <p v-if="imageFile" class="mt-2 text-sm text-green-500">{{ imageFile.name }}</p>

        <!-- Default image preview -->
        <div v-if="!imageUrl && defaultImage" class="image-overlay relative mb-4 overflow-hidden" :class="cropClasses">
            <img :src="'/' + defaultImage" alt="Default Profile" class="w-32 h-32 object-cover" :class="cropClasses" />
            <div class="image-mask absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 z-10">
                <label class="cursor-pointer absolute top-0 right-0 left-0 bottom-0 text-white flex items-center justify-center">
                    <Camera height="32" width="32" />
                    <input accept=".jpg, .jpeg, .png" type="file" class="hidden" @change="selectImage"/>
                </label>
            </div>
        </div>

        <!-- Empty state -->
        <div v-if="!imageUrl && !defaultImage" class="image-overlay h-[200px] w-[200px] border border-dashed border-[#ADB2BD] relative mb-4 overflow-hidden" :class="cropClasses">
            <label class="cursor-pointer absolute top-0 right-0 left-0 bottom-0 text-white flex flex-col gap-2 text-center items-center justify-center">
                <Camera height="32" width="32" />
                Upload your profile banner
                <input accept=".jpg, .jpeg, .png" type="file" class="hidden" @change="selectImage"/>
            </label>
        </div>

        <!-- Image Preview / Cropper -->
        <div v-if="imageUrl" class="relative mb-4 h-fit">
            <img v-if="showCropper" :src="imageUrl" alt="Preview" ref="imageRef" class="max-w-full h-auto" @load="onImageLoad" />

            <!-- Cropper controls (only visible if cropper is shown) -->
            <div v-if="showCropper" class="flex gap-2 mt-4">
                <InputButton @click="cancelCrop" variant="secondary">Cancel</InputButton>
                <InputButton @click="confirmCrop">Confirm</InputButton>
            </div>

            <!-- Cropped Image Preview -->
            <div v-if="croppedImageUrl" class="mb-4">
                <img
                    :src="croppedImageUrl"
                    alt="Cropped Image"
                    class="object-cover"
                    :class="cropClasses"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>
.hidden {
    display: none;
}
</style>
