<script setup>
import { ArrowRight, Heart, LogOut, Menu, Search, Settings, User } from '@iconoir/vue';
import Logo from '@/Components/Logo.vue'
import NavLink from "@/Components/Layout/NavLink.vue";
import NotificationBadge from "@/Components/Yoclo/NotificationBadge.vue";
import {usePage} from "@inertiajs/vue3";
import DropDown from "@/Components/Layout/DropDown.vue";
import DropDownLink from "@/Components/Layout/DropDownLink.vue";
import ResponsiveNavLink from "@/Components/Layout/ResponsiveNavLink.vue";
import {computed, ref} from "vue";

const page = usePage();
const {user, totalFavourites} = page.props.auth;

// Define props (you can pass other props as needed)
const props = defineProps({
    href: {
        type: String,
        required: true,
    },
    csrf_token: {
        type: String,
        required: true,
    }
});

console.log(user);

const open = ref(false);
const searchQuery = ref(new URLSearchParams(window.location.search).get('search') || ''); // Extract search query from URL

const isActive = computed((url) => {
    return page.url === url;
})

const getPageUrl = () => {
    const parts = page.url.split('/')[1];
    return parts.charAt(0).toUpperCase() + parts.slice(1)
}

// Utility function to debounce the execution of a function
const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
};

// Debounced event emission
const emitSearchEvent = debounce(() => {
    const searchEvent = new CustomEvent('search-updated', { detail: searchQuery.value });
    window.dispatchEvent(searchEvent); // Emit the event globally
}, 300); // Debounced by 300ms or adjust as needed

// Debounced redirect logic (triggered only on Enter key)
const debouncedRedirect = debounce(() => {
    window.location.href = user.account_type === 'creator'
        ? `/brands?search=${searchQuery.value}`
        : `/creators?search=${searchQuery.value}`;
}, 500); // Debounced by 500ms

// Function to handle both event emission and redirect logic
const handleSearch = (event) => {
    const currentUrl = window.location.pathname;

    if (currentUrl === '/creators' || currentUrl === '/brands') {
        // Emit debounced event for dynamic loading when on creators or brands page
        emitSearchEvent();
    }

    // Only trigger the redirect when Enter is pressed
    if (event.key === 'Enter') {
        if (currentUrl !== '/creators' && currentUrl !== '/brands') {
            debouncedRedirect();
        }
    }
};

function logout() {
    axios.post('/logout').then(() => {
        window.location = '/';
    });
}

</script>

<template>
    <header class="fixed left-0 right-0 top-0 z-50 bg-black">
        <div class="container px-5 lg:px-3">
            <!-- Primary Navigation Menu -->
            <nav>
                <div class="flex md:justify-between items-center py-4 md:pt-16 md:py-8">
                    <button class="md:hidden" @click="open = !open">
                        <Menu class="w-6 h-6 md:hidden"/>
                    </button>

                    <button class="ml-8 md:hidden">
                        <Search class="w-6 h-6 md:hidden"/>
                    </button>

                    <div class="flex mx-auto md:mx-0 justify-center">
                        <!-- Logo -->
                        <div>
                            <a href="/profile">
                                <logo class="block h-12 w-auto fill-current text-gray-800 dark:text-white"/>
                            </a>
                        </div>

                        <!-- Navigation Links -->
                        <div class="hidden md:block md:space-x-6 lg:space-x-12 sm:-my-px md:ml-8 lg:ml-20">
                            <nav-link href="/creators" :active="page.url === '/creators'">Creators</nav-link>
                            <nav-link href="/brands" :active="page.url === '/brands'">Brands</nav-link>
                        </div>
                    </div>

                    <!-- Search Dropdown -->
                    <div class="flex items-center sm:ml-6 gap-6 lg:gap-12">
                        <div class="relative hidden md:block">
                            <input
                                v-model="searchQuery"
                                @keyup="handleSearch"
                                class="form-control border py-2 !rounded-full text-white !border-white min-w-[265px] !bg-transparent pl-14"
                            />

                            <Search class="absolute pointer-events-none left-[10px] top-1/2 -translate-y-1/2" />
                        </div>

                        <a href="/favourites" class="relative text-white">
                            <Heart class="w-6 h-6 md:w-8 md:h-8" />
                            <notification-badge :content="totalFavourites"></notification-badge>
                        </a>

                        <drop-down align="right" width="48">
                            <!-- Trigger -->
                            <template #trigger>
                                <button class="inline-flex relative items-center justify-center border-2 border-white w-12 h-12 md:w-14 md:h-14 rounded-full text-sm leading-4 font-medium overflow-hidden bg-white dark:bg-black focus:outline-none transition ease-in-out duration-150 text-white">
                                    <img v-if="user.profile_picture" :src="user.profile_picture" alt="" />
                                    <User />
                                </button>
                            </template>

                            <!-- Dropdown Content -->
                            <template #content>
                                <drop-down-link :href="user.account_type === 'brand' ? '/brand-profile' : '/profile'">
                                    <User class="me-2" />
                                    Profile
                                </drop-down-link>
                                <drop-down-link href="#">
                                    <Settings class="me-2" />
                                    Settings
                                </drop-down-link>
                                <drop-down-link href="#" @click="logout">
                                    <LogOut class="me-2" />
                                    Log Out
                                </drop-down-link>
                            </template>
                        </drop-down>
                    </div>
                </div>
            </nav>

            <!-- Responsive Navigation Menu -->
            <div :class="{'block': open, 'hidden': ! open}">
                <div class="pt-2 pb-3 space-y-1">
                    <responsive-nav-link href="/profile" :active="isActive">
                        Dashboard
                    </responsive-nav-link>
                </div>

                <!-- Responsive Settings Options -->
                <div class="pt-4 pb-1 border-t border-gray-200 dark:border-gray-600">
                    <div class="px-4">
                        <div class="font-medium text-base text-gray-800 dark:text-gray-200"></div>
                        <div class="font-medium text-sm text-gray-500">{{ user.email }}</div>
                    </div>

                    <div class="mt-3 space-y-1">
                        <responsive-nav-link :href="`${user.type} === 'brand' ? '/brand-profile' : '/profile'`">
                            Profile
                        </responsive-nav-link>

                        <!-- Authentication -->
                        <button @click="logout()" class="w-full text-left">
                            <responsive-nav-link>
                                Log Out
                            </responsive-nav-link>
                        </button>
                    </div>
                </div>
            </div>

            <ul class="flex items-center pb-2 gap-3 hidden">
                <li>
                    <a href="#" class="flex items-center text-white gap-3">
                        Home
                        <ArrowRight/>
                    </a>
                </li>
                <li>
                    <a href="#" class="flex items-center text-white gap-3">
                        {{ getPageUrl() }}
                        <ArrowRight />
                    </a>
                </li>

                <li class="text-white opacity-60">{{ user.first_name }} {{ user.last_name}}</li>
            </ul>
        </div>
    </header>
</template>
