<script setup>
const props = defineProps({
    data: Object
});

// Function to append existing query params to the pagination links correctly
const appendQueryParams = (url) => {
    const currentParams = new URLSearchParams(window.location.search);

    // Remove 'page' parameter from the current query params (since it's already in the pagination links)
    currentParams.delete('page');

    // If there are other query parameters, append them to the URL
    const queryString = currentParams.toString();

    // If the URL already has query parameters, append with &, otherwise use ?
    return queryString ? `${url}&${queryString}` : url;
};
</script>

<template>
    <nav class="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
        <div class="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0" v-if="data && data.links">
            <div class="hidden md:-mt-px md:flex">
                <template v-for="(link, index) in data.links" :key="index">
                    <!-- Ignore rendering for the 'Previous' and 'Next' links in this section to avoid duplication -->
                    <a
                        v-if="link.url !== null && link.label !== 'Next' && link.label !== 'Previous'"
                        :href="appendQueryParams(link.url)"
                        :class="[
                        'inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium',
                        link.active ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                    ]"
                        v-html="link.label"
                    ></a>

                    <span
                        v-else-if="link.label === '...'"
                        class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500"
                        v-html="link.label"
                    ></span>
                </template>
            </div>
        </div>
    </nav>
</template>
