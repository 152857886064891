export const marketTagOptions = [
    { id: 'art', name: 'Art', icon: 'DesignPencil' },
    { id: 'augmented_reality', name: 'Augmented Reality', icon: 'AugmentedReality' },
    { id: 'automotive', name: 'Automotive', icon: 'Car' },
    { id: 'beauty', name: 'Beauty', icon: 'Heart' },
    { id: 'books', name: 'Books', icon: 'OpenBook' },
    { id: 'business', name: 'Business', icon: 'Suitcase' },
    { id: 'comedy', name: 'Comedy', icon: 'EmojiTalkingHappy' },
    { id: 'content_creation', name: 'Content Creation', icon: 'Post' },
    { id: 'cooking', name: 'Cooking', icon: 'Bbq' },
    { id: 'culture', name: 'Culture', icon: 'Globe' },
    { id: 'cybersecurity', name: 'Cybersecurity', icon: 'HistoricShield' },
    { id: 'data_science', name: 'Data Science', icon: 'Database' },
    { id: 'diy', name: 'DIY', icon: 'Hammer' },
    { id: 'education', name: 'Education', icon: 'GraduationCap' },
    { id: 'entrepreneurship', name: 'Entrepreneurship', icon: 'Strategy' },
    { id: 'entertainment', name: 'Entertainment', icon: 'ModernTv' },
    { id: 'event_planning', name: 'Event Planning', icon: 'Calendar' },
    { id: 'fashion', name: 'Fashion', icon: 'Handbag' },
    { id: 'film', name: 'Film', icon: 'CinemaOld' },
    { id: 'fitness', name: 'Fitness', icon: 'Gym' },
    { id: 'food', name: 'Food', icon: 'Cutlery' },
    { id: 'gaming', name: 'Gaming', icon: 'Gamepad' },
    { id: 'gardening', name: 'Gardening', icon: 'AugmentedReality' },
    { id: 'health', name: 'Health', icon: 'HealthShield' },
    { id: 'home_decor', name: 'Home Decor', icon: 'Sofa' },
    { id: 'lifestyle', name: 'Lifestyle', icon: 'Activity' },
    { id: 'mental_health', name: 'Mental Health', icon: 'Brain' },
    { id: 'music', name: 'Music', icon: 'MusicDoubleNote' },
    { id: 'nutrition', name: 'Nutrition', icon: 'Apple' },
    { id: 'outdoors', name: 'Outdoors', icon: 'PineTree' },
    { id: 'parenting', name: 'Parenting', icon: 'UserLove' },
    { id: 'personal_finance', name: 'Personal Finance', icon: 'Coins' },
    { id: 'pets', name: 'Pets', icon: 'Wolf' },
    { id: 'photography', name: 'Photography', icon: 'Camera' },
    { id: 'politics', name: 'Politics', icon: 'PeopleTag' },
    { id: 'real_estate', name: 'Real Estate', icon: 'City' },
    { id: 'science', name: 'Science', icon: 'Flask' },
    { id: 'skincare', name: 'Skincare', icon: 'Soap' },
    { id: 'sports', name: 'Sports', icon: 'Running' },
    { id: 'sustainability', name: 'Sustainability', icon: 'Leaf' },
    { id: 'technology', name: 'Technology', icon: 'SmartphoneDevice' },
    { id: 'travel', name: 'Travel', icon: 'Airplane' },
    { id: 'video_production', name: 'Video Production', icon: 'VideoCamera' },
    { id: 'virtual_reality', name: 'Virtual Reality', icon: 'VrTag' },
    { id: 'wellness', name: 'Wellness', icon: 'Healthcare' },
    { id: 'writing', name: 'Writing', icon: 'EditPencil' },
];
