<script setup>
import {usePage} from "@inertiajs/vue3";
import SvgIcon from "@/Components/SvgIcon.vue";
import Badge from "@/Components/Layout/Badge.vue";
import Layout from '@/Layouts/Authed.vue'
import Modal from "@/Components/Layout/Modal.vue";
import {ref} from "vue";
import InputButton from "@/Components/Forms/InputButton.vue";
import BrandProfileSettings from "@/Components/Yoclo/BrandProfileSettings.vue";
import {marketTagOptions} from "@/data/marketTagOptions.js";
import {getMarketTagIcon} from "@/utils/helpers.js";

const page = usePage();
const {user} = page.props.auth;

const isModalOpen = ref(false);

const showModal = () => {
    isModalOpen.value = true;
};

console.log('brandUser', user);

</script>

<template>
    <Layout>
        <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 text-white relative z-20">
            <div class="relative mb-12">
                <div class="h-[70px] w-full flex items-center justify-end">
                    <input-button @click="showModal">Update profile page</input-button>
                </div>

                <div class="mx-auto relative z-20">
                    <div class="flex flex-col md:flex-row justify-between">
                        <div class="flex flex-col md:flex-row lg:items-center">
                            <div class="flex me-7 md:me-14 aspect-square" v-if="user.profile_picture">
                                <!-- This could be change to brand_logo or can stay as profile picture -->
                                <img v-if="user.profile_picture" :src="user.profile_picture" alt="brand-logo"/>
                            </div>

                            <div>
                                <div class="flex items-center text-xl leading-tight">
                                    Collaboration Interests:
                                    <Badge v-for="(tag, index) in user?.market_tags" :class="index !== 0 && 'ms-2'">
                                        <component v-if="marketTagOptions[index].icon"
                                                   :is="getMarketTagIcon(tag.name)"/>
                                        {{ tag.name }}
                                    </Badge>
                                </div>

                                <!-- Should be brand name rather than user name. The value exists in the DB but it's not being pulled through. -->
                                <h1 class="text-7xl font-semibold leading-tight">{{ user.name }}</h1>
                                <h2 class="text-2xl mb-5 leading-tight">1 TotalFollowing</h2>

                                <!--                                <x-social-counts :user="auth()->user()"/>-->

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Tabs -->
            <div>
                <!-- Tab List -->
                <div class="flex items-center">
                    <ul
                        role="tablist"
                        class="flex items-stretch mb-6"
                    >
                        <!-- Tab -->
                        <li>Overview</li>
                        <li>About</li>
                    </ul>
                </div>

                <!-- Panels -->
                <div role="tabpanels">
                    <!-- Panel -->
                    <section
                        role="tabpanel"
                    >
                        <div class="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-12">
                            <div v-for="item in [1, 2, 3, 4, 5, 6]">
                                <svg-icon name="tiktok"/>
                                <span class="inline-block ms-2 mb-2">5 hours ago</span>
                                <div class="shadow-lg rounded-3xl h-[488px] bg-[#171B1F]"></div>
                                <ul class="flex justify-between font-light mt-5">
                                    <li>
                                        5000 <strong class="font-medium">Likes</strong>
                                    </li>
                                    <li>
                                        400000 <strong class="font-medium">Comments</strong>
                                    </li>
                                    <li>
                                        100000 <strong class="font-medium">Views</strong>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>

        <modal v-model="isModalOpen">
            <brand-profile-settings/>
        </modal>
    </Layout>
</template>
