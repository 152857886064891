<script setup>
const props = defineProps(['btn-label', 'btn-href']);
</script>

<template>
    <v-container fluid>
        <v-row class="hr-bottom">
            <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                    <template v-slot:divider>
                        <v-icon icon="mdi-chevron-right"></v-icon>
                    </template>
                </v-breadcrumbs>
            </v-col>

            <v-col v-if="props.btnLabel !== undefined" class="pt-6 pr-4">
                <v-btn variant="outlined" size="small" class="float-right" :href="props.btnHref">{{ props.btnLabel }}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        breadcrumbs: [],
    }),
    mounted() {
        this.$eventBus.on("breadcrumb-nav", breadcrumbs => {
            this.breadcrumbs = breadcrumbs;
        });
    }
}
</script>
