<script setup>
import { ref, computed } from 'vue';
import {useForm, usePage} from '@inertiajs/vue3';
import Papa from 'papaparse'; // For CSV parsing
import * as XLSX from 'xlsx'; // For Excel parsing
import Layout from "@/Layouts/Admin.vue";
import TopBar from "@/Components/Admin/TopBar.vue";

// Reactive data to store the table preview and file
const dataPreview = ref([]);
const fileError = ref('');
const form = useForm({
    fileData: null, // Data from the file
});

const { props } = usePage();

// Pagination state
const currentPage = ref(1);
const rowsPerPage = ref(10);

// Function to handle file drop
const handleFileDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    processFile(file);
};

// Function to handle file selection via input
const handleFileInput = (event) => {
    const file = event.target.files[0];
    processFile(file);
};

// Function to process CSV or Excel files
const processFile = (file) => {
    if (!file) return;

    // Reset state
    dataPreview.value = [];
    fileError.value = '';

    // Check file type
    const fileType = file.type;
    if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || fileType === 'application/vnd.ms-excel') {
        // Process Excel file
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
            dataPreview.value = jsonData;
            form.fileData = jsonData;
        };
        reader.readAsArrayBuffer(file);
    } else if (fileType === 'text/csv') {
        // Process CSV file
        Papa.parse(file, {
            complete: (result) => {
                dataPreview.value = result.data;
                form.fileData = result.data;
            },
            header: false,
        });
    } else {
        fileError.value = 'Unsupported file type. Please upload a CSV or Excel file.';
    }
};

const paginatedData = computed(() => {
    const start = (currentPage.value - 1) * rowsPerPage.value + 1; // Start from the second row
    const end = start + rowsPerPage.value;

    return dataPreview.value
        .slice(1) // Exclude the header
        .filter(row => row.some(cell => cell !== '')) // Omit rows with all empty cells
        .slice(start - 1, end - 1); // Paginate filtered data
});

// Function to calculate the total number of pages
const totalPages = computed(() => {
    return Math.ceil(dataPreview.value.length / rowsPerPage.value);
});

// Function to go to the next page
const nextPage = () => {
    if (currentPage.value < totalPages.value) {
        currentPage.value++;
    }
};

// Function to go to the previous page
const prevPage = () => {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
};


// Function to submit the form
const submit = () => {
    form.post('/admin/users/import', {
        onSuccess: () => {
            // Handle success (optional), e.g., reset form or show notification
            dataPreview.value = [];
        },
    });
};
</script>

<template>
    <Layout>
        <top-bar title="Import Users"></top-bar>

        <div class="max-w-3xl mx-auto mt-8 text-black">
            <div v-if="props.flash.success" class="text-green-500">
                {{ props.flash.success }}
            </div>

            <div
                class="col-span-full cursor-pointer hover:bg-gray-50"
                @dragover.prevent
                @drop="handleFileDrop"
                @click="$refs.fileInput.click()"
            >
                <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div class="text-center">
                    <div class="mt-4 flex text-sm leading-6 text-gray-600">
                        <label for="file-upload" class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                            <span>Upload</span>
                            <input type="file" ref="fileInput" @change="handleFileInput" class="sr-only" accept=".csv, .xls, .xlsx" />
                        </label>
                        <p class="pl-1">or drag and drop a CSV or Excel file here</p>
                    </div>
                </div>
            </div>
          </div>    

            <!-- Display error if file type is unsupported -->
            <div v-if="fileError" class="text-red-500 mt-4">{{ fileError }}</div>

            <div v-if="dataPreview.length" class="flex items-left mt-4">
                <select v-model="rowsPerPage" class="border bg-white text-black border-gray-300 rounded-md p-1">
                    <option :value="5" selected>5</option>
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                </select>
                <span class="p-1">results per page</span>
            </div>

            <!-- Data preview table -->
            <div v-if="dataPreview.length" class="mt-6 overflow-auto">
                <table class="min-w-full bg-white shadow-md">
                    <thead>
                    <tr class="bg-gray-50">
                        <th v-for="(col, index) in dataPreview[0]" :key="index" class="py-2 px-4 text-left text-sm font-semibold text-gray-700">
                            {{ col }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, rowIndex) in paginatedData" :key="rowIndex" class="border-t">
                        <td v-for="(cell, cellIndex) in row" :key="cellIndex" class="py-2 px-4 text-sm text-gray-700">
                            {{ cell }}
                        </td>
                    </tr>
                    </tbody>
                </table>

                <!-- Pagination Controls -->
                <div class="mt-6 flex justify-between" v-if="dataPreview.length > rowsPerPage">
                    <button
                        @click="prevPage"
                        :disabled="currentPage === 1"
                        class="bg-gray-300 px-4 py-2 rounded-md disabled:opacity-50">
                        Previous
                    </button>
                    <span>Page {{ currentPage }} of {{ totalPages }}</span>
                    <button
                        @click="nextPage"
                        :disabled="currentPage === totalPages"
                        class="bg-gray-300 px-4 py-2 rounded-md disabled:opacity-50">
                        Next
                    </button>
                </div>
            </div>

            <div class="mt-6" v-if="dataPreview.length">
                <button
                    @click="submit"
                    class="w-full bg-green-500 text-white font-semibold py-2 px-4 rounded-md shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
                    :disabled="!dataPreview.length || form.processing">
                    Import {{ dataPreview.length - 1 }} Users
                </button>
            </div>
        </div>
    </Layout>
</template>
