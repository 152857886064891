<template>
    <div class="relative cursor-pointer h-7 w-7 me-4">
        <input
            :id="id"
            type="checkbox"
            :name="name"
            :checked="modelValue"
            :class="computedClass"
            @change="$emit('update:modelValue', $event.target.checked)"
        />
        <Check
            v-if="modelValue"
            @click="$emit('update:modelValue', $event.target.checked)"
            class="absolute top-2/4 left-2/4 transform -translate-x-1/2 -translate-y-1/2"
        />
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { Check } from "@iconoir/vue";

// Define props for the checkbox component
const props = defineProps({
    modelValue: Boolean,  // v-model binding for checkbox checked state
    id: String,
    name: String,
    class: String  // Allow custom class overrides
})

// Compute the class for the checkbox (allow custom class overrides)
const computedClass = computed(() => {
    return `relative appearance-none border border-[#F7F7F7] rounded-[6px] h-7 w-7 shadow-sm focus-visible:ring-indigo-500 dark:focus-visible:ring-indigo-600 dark:focus-visible:ring-offset-gray-800 ${props.class || ''}`
})
</script>

<style>
/* Custom styles for checkbox */
</style>
