import './bootstrap';
import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { createI18n } from 'vue-i18n'
import "@mdi/font/css/materialdesignicons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import '@fontsource/poppins/100.css';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900.css';
import { VueStripePlugin } from '@vue-stripe/vue-stripe';
import '@shoelace-style/shoelace/dist/shoelace.js';

// make sure to also import the corresponding css
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "@fortawesome/fontawesome-free/css/all.css";
import clickOutsideDirective from './directives/clickOutside';
import axios from 'axios';

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

// Dynamically import all translation files from `resources/lang`
const messages = Object.fromEntries(
    Object.entries(import.meta.glob('../../lang/*.json', { eager: true })).map(
        ([key, value]) => {
            const locale = key.split('/').pop().replace('.json', '');
            return [locale, value.default];
        }
    )
);

// Create the i18n instance
const i18n = createI18n({
    locale: 'en', // Set the default locale
    fallbackLocale: 'en', // Set the fallback locale
    messages, // Include the dynamically loaded translations
});

createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true });
        return pages[`./Pages/${name}.vue`];
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(i18n)
            .use(VueStripePlugin, { pk: import.meta.env.VITE_STRIPE_KEY })
            .directive('click-outside', clickOutsideDirective)
            .mount(el);
    },
});
