
<script setup>
import Layout from '../Layouts/Default.vue'
import { Head, useForm } from '@inertiajs/vue3'
import SvgIcon from "@/Components/SvgIcon.vue";

const props = defineProps({
    user: Object,
    verificationCode: String
});

console.log(props.user);

const form = useForm({
    password: '',
    confirm_password: ''
});

const submit = () => {
    if (form.password !== form.confirm_password) {
        form.errors.confirm_password = 'Passwords do not match';
        return;
    }

    form.post('/update-password/' + props.verificationCode);
};
</script>

<template>
    <Head>
        <title>Set your password - Yoclo</title>
    </Head>

    <Layout>
        <div class="relative flex flex-col min-h-screen">
            <div class="container my-auto px-9 md:pt-14 md:px-10 md:pb-[72px] max-w-[485px] md:bg-[#181A1B] flex flex-col !text-white sm:rounded-lg relative z-10 md:shadow-2xl">

                <a href="/" class="inline-block mb-9 -ms-1"><svg-icon name="arrow-left"></svg-icon></a>

                <h2 class="text-3xl font-medium mb-6">Welcome {{ props.user.first_name }} Set your password.</h2>

                <p>Please enter your account password to use below, your account will be verified after this.</p>

                <form class="mt-3" @submit.prevent="submit">
                    <div class="mb-4">
                        <label for="password" class="block text-sm font-medium text-gray-300 mb-1">Password</label>
                        <input
                            v-model="form.password"
                            type="password"
                            id="password"
                            class="w-full px-4 py-2 rounded-md bg-gray-800 border border-gray-600 focus:border-indigo-500 focus:ring-indigo-500 text-white"
                            required
                        />
                    </div>

                    <!-- Confirm Password Field -->
                    <div class="mb-4">
                        <label for="confirm_password" class="block text-sm font-medium text-gray-300 mb-1">Confirm Password</label>
                        <input
                            v-model="form.confirm_password"
                            type="password"
                            id="confirm_password"
                            class="w-full px-4 py-2 rounded-md bg-gray-800 border border-gray-600 focus:border-indigo-500 focus:ring-indigo-500 text-white"
                            required
                        />
                        <!-- Error message for password mismatch -->
                        <div v-if="form.errors.confirm_password" class="text-red-500 text-sm mt-2">
                            {{ form.errors.confirm_password }}
                        </div>
                    </div>

                    <!-- Submit Button -->
                    <div class="mt-6">
                        <button
                            type="submit"
                            class="w-full bg-blue-600 text-white font-semibold py-2 px-4 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            :disabled="form.processing"
                        >
                            Set Password
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </Layout>
</template>

<style lang="scss" scoped>

</style>
