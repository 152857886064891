<script setup>
import { useForm } from '@inertiajs/vue3';
import { Head } from '@inertiajs/vue3';
import Layout from "@/Layouts/Admin.vue";
import ImageUploadWithCrop from "@/Components/ImageUploadWithCrop.vue";
import Multiselect from "vue-multiselect";
import InputLabel from "@/Components/Forms/InputLabel.vue";
import InputTextArea from "@/Components/Forms/InputTextArea.vue";
import TopBar from "@/Components/Admin/TopBar.vue";

// Define props to inject user data
const props = defineProps({
    user: Object,
    transactions: Array,
    flash: Object
});

console.log(props.transitions);

// Inertia form setup, pre-populate form fields with user data
const form = useForm({
    email: props.user.email || '',
    account_type: props.user.account_type || '',
    biography: props.user.biography || null,
    show_alias: props.user.show_alias || false,
    professional_alias: props.user.professional_alias || null,
    first_name: props.user.first_name || null,
    last_name: props.user.last_name || null,
    password: null,  // Leave password empty for user to fill in manually
    remember: false,
    market_tags: props.user.market_tags || null,
    profile_picture: props.user.profile_picture || null, // Assuming user may upload a profile picture
    cover_photo: props.user.cover_photo || null // Assuming user may upload a profile picture
});

const options = [
    { id: 'art', name: 'Art' },
    { id: 'augmented_reality', name: 'Augmented Reality' },
    { id: 'automotive', name: 'Automotive' },
    { id: 'beauty', name: 'Beauty' },
    { id: 'blockchain_and_cryptocurrency', name: 'Blockchain and Cryptocurrency' },
    { id: 'books_and_literature', name: 'Books and Literature' },
    { id: 'comedy', name: 'Comedy' },
    { id: 'content_creation', name: 'Content Creation' },
    { id: 'cooking', name: 'Cooking' },
    { id: 'culture', name: 'Culture' },
    { id: 'cybersecurity', name: 'Cybersecurity' },
    { id: 'data_science', name: 'Data Science' },
    { id: 'diy_crafts', name: 'DIY Crafts' },
    { id: 'education', name: 'Education' },
    { id: 'entrepreneurship', name: 'Entrepreneurship' },
    { id: 'event_planning', name: 'Event Planning' },
    { id: 'fashion', name: 'Fashion' },
    { id: 'fashion_design', name: 'Fashion Design' },
    { id: 'film_and_movies', name: 'Film and Movies' },
    { id: 'fitness', name: 'Fitness' },
    { id: 'food', name: 'Food' },
    { id: 'gardening', name: 'Gardening' },
    { id: 'gaming', name: 'Gaming' },
    { id: 'health', name: 'Health' },
    { id: 'history', name: 'History' },
    { id: 'home_decor', name: 'Home Decor' },
    { id: 'lifestyle', name: 'Lifestyle' },
    { id: 'luxury_goods', name: 'Luxury Goods' },
    { id: 'mental_health', name: 'Mental Health' },
    { id: 'minimalism', name: 'Minimalism' },
    { id: 'music', name: 'Music' },
    { id: 'nutrition', name: 'Nutrition' },
    { id: 'outdoor_adventure', name: 'Outdoor Adventure' },
    { id: 'parenting', name: 'Parenting' },
    { id: 'personal_finance', name: 'Personal Finance' },
    { id: 'pet_care', name: 'Pet Care' },
    { id: 'photography', name: 'Photography' },
    { id: 'product_reviews', name: 'Product Reviews' },
    { id: 'real_estate', name: 'Real Estate' },
    { id: 'science', name: 'Science' },
    { id: 'skincare', name: 'Skincare' },
    { id: 'social_media_marketing', name: 'Social Media Marketing' },
    { id: 'software_development', name: 'Software Development' },
    { id: 'sports', name: 'Sports' },
    { id: 'sustainability', name: 'Sustainability' },
    { id: 'technology', name: 'Technology' },
    { id: 'travel', name: 'Travel' },
    { id: 'virtual_reality', name: 'Virtual Reality' },
    { id: 'wedding_planning', name: 'Wedding Planning' },
    { id: 'wellness', name: 'Wellness' }
];
// Function to handle form submission (PUT request for editing the user)
const submit = () => {
    form.put(`/admin/users/edit/${props.user.id}`);
};
</script>

<template>
    <Head>
        <title>Edit User</title>
    </Head>
    <Layout>
        <top-bar :title="'Edit User &rarr; ' + form.email"></top-bar>
        <div class="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-md mt-8 text-black">
            <div v-if="flash?.success" class="text-green-500 mb-3">
                {{ flash?.success }}
            </div>

            <div v-if="form?.errors?.error" class="text-red-500 text-sm mt-1 mb-3">
                {{ form?.errors?.error }}
            </div>

            <form @submit.prevent="submit">
                <div class="mb-4">
                    <label for="account_type" class="block text-sm font-medium text-gray-700">Account Type</label>
                    <select
                        v-model="form.account_type"
                        id="account_type"
                        class="mt-1 bg-white block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <option value="" disabled>Select account type</option>
                        <option value="admin">Admin</option>
                        <option value="brand">Brand</option>
                        <option value="creator">Creator</option>
                        <option value="agency">Agency</option>
                    </select>

                    <div v-if="form.errors.account_type" class="text-red-500 text-sm mt-2">
                        {{ form.errors.account_type }}
                    </div>
                </div>

                <div class="mb-4">
                    <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
                    <input
                        v-model="form.email"
                        type="email"
                        id="email"
                        class="mt-1 bg-white block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Enter user's email"
                    />

                    <div v-if="form.errors.email" class="text-red-500 text-sm mt-2">{{ form.errors.email }}</div>
                </div>

                <div class="grid gap-6 mb-14">
                    <div class="max-w-md">
                        <label for="first_name">First name</label>
                        <input
                            v-model="form.first_name"
                            id="first_name"
                            name="first_name"
                            type="text"
                            class="mt-1 bg-white block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            autocomplete="first_name"
                        />
                        <div v-if="form.errors.first_name" class="text-red-500 text-sm mt-1">
                            {{ form.errors.first_name }}
                        </div>
                    </div>
                    <div class="max-w-md">
                        <label for="last_name">Last name</label>
                        <input
                            v-model="form.last_name"
                            id="last_name"
                            name="last_name"
                            type="text"
                            class="mt-1 bg-white block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            autocomplete="last_name"
                        />

                        <div v-if="form.errors.last_name" class="text-red-500 text-sm mt-1">
                            {{ form.errors.last_name }}
                        </div>
                    </div>
                    <div class="grid gap-6 grid-cols-2">
                        <div>
                            <label for="professional_alias">Professional Alias (Optional)</label>
                            <input
                                v-model="form.professional_alias"
                                id="professional_alias"
                                name="professional_alias"
                                type="text"
                                class="mt-1 bg-white block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                autocomplete="professional_alias"
                            />

                            <div v-if="form.errors.professional_alias" class="text-red-500 text-sm mt-1">
                                {{ form.errors.professional_alias }}
                            </div>
                        </div>

                        <div class="flex items-center mt-6">
                            <input
                                type="checkbox"
                                v-model="form.show_alias"
                                id="show_alias"
                                name="show_alias"
                                class="me-4 appearance-none border border-[#F7F7F7] rounded-[6px] h-7 w-7 shadow-sm focus-visible:ring-indigo-500 dark:focus-visible:ring-indigo-600 dark:focus-visible:ring-offset-gray-800"
                            />
                            <label for="show_alias" class="max-w-60">Show Professional Alias on profile instead of First and Last name</label>
                        </div>

                        <div v-if="form.errors.show_alias" class="text-red-500 text-sm mt-1">
                            {{ form.errors.show_alias }}
                        </div>
                    </div>
                </div>

                <div class="max-w-md">
                    <h4>Profile Picture</h4>
                    <image-upload-with-crop
                        :default-image="form.profile_picture"
                        aspect-ratio="1"
                        :crop-box-resizable="true"
                        :crop-box-width="200"
                        :crop-box-height="200"
                        crop-classes="rounded-full"
                        border-radius="50%"
                        v-model="form.profile_picture"/>

                    <div v-if="form.errors.profile_picture" class="text-red-500 text-sm mt-1">
                        {{ form.errors.profile_picture }}
                    </div>
                </div>

                <div class="max-w-md">
                    <h4>Profile Banner</h4>
                    <image-upload-with-crop
                        aspect-ratio="5/2"
                        :crop-box-resizable="true"
                        crop-box-width="100%"
                        crop-box-height="200"
                        border-radius="0"
                        :default-image="form.cover_photo"
                        v-model="form.cover_photo"/>

                    <div v-if="form.errors.cover_photo" class="text-red-500 text-sm mt-1">
                        {{ form.errors.cover_photo }}
                    </div>
                </div>

                <div>
                    <input-label for="biography" value="Biography"/>
                    <textarea
                        v-model="form.biography"
                        rows="5"
                        id="biography"
                        name="biography"
                        type="text"
                        class="mt-1 bg-white block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        autocomplete="biography"
                    />

                    <div v-if="form.errors.biography" class="text-red-500 text-sm mt-1">
                        {{ form.errors.biography }}
                    </div>
                </div>

                <div class="max-w-md">
                    <input-label for="tags" value="Add market tags (maximum 3)" class="mb-2" />
                    <multiselect
                        :show-labels="false"
                        :close-on-select="false"
                        :multiple="true"
                        :options="options"
                        track-by="id"
                        label="name"
                        :max="3"
                        v-model="form.market_tags"
                    />
                </div>

                <div class="mt-6">
                    <button
                        type="submit"
                        class="w-full bg-green-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
                        :disabled="form.processing"
                    >
                        Update User
                    </button>
                </div>
            </form>
        </div>
    </Layout>
</template>
