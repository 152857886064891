<script>
import ConnectedAccount from "@/Components/Yoclo/ConnectedAccount.vue";
import {defineComponent} from "vue";

export default defineComponent({
    components: {ConnectedAccount}
})

</script>

<template>
    <div class="grid md:grid-cols-2 gap-[20px]">
        <connected-account :platform="'instagram'" :key="'connected-account-instagram'"/>
        <connected-account :platform="'facebook'" :key="'connected-account-facebook'"/>
        <connected-account :platform="'youtube'" :key="'connected-account-youtube'"/>
        <connected-account :platform="'tiktok'" :key="'connected-account-tiktok'"/>
    </div>
</template>
