<script setup>
import {computed, ref} from 'vue'

// Props for alignment and width
const props = defineProps({
    align: {
        type: String,
        default: 'right',
    },
    width: {
        type: String,
        default: '48',
    },
})

// Control for dropdown visibility
const open = ref(false)

const toggleDropdown = () => {
    open.value = !open.value;
};

const closeDropdown = () => {
    open.value = false;
};

// Classes for alignment based on the prop
const alignmentClasses = computed(() => {
    switch (props.align) {
        case 'left':
            return 'origin-top-left left-0'
        case 'top':
            return 'origin-top'
        case 'right':
        default:
            return 'origin-top-right right-0'
    }
})

// Width classes based on the prop
const widthClass = computed(() => {
    switch (props.width) {
        case '48':
            return 'w-48'
        case '53':
            return 'w-53'
        default:
            return 'w-48'
    }
})
</script>

<template>
    <div class="relative" v-click-outside="closeDropdown">
        <!-- Trigger Slot -->
        <div @click="toggleDropdown">
            <slot name="trigger"></slot>
        </div>

        <!-- Dropdown Content -->
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0 scale-95"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-95"
        >
            <div v-if="open" class="absolute z-50 rounded-md shadow-lg" :class="[alignmentClasses, widthClass]">
                <div class="rounded-md ring-1 ring-black ring-opacity-5 py-1 bg-white dark:bg-[#21262A]">
                    <slot name="content"></slot>
                </div>
            </div>
        </transition>
    </div>
</template>

<style>
/* Add any additional styling here */
</style>
