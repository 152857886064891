<script setup>
import Layout from '@/Layouts/Authed.vue'
import { usePage } from "@inertiajs/vue3";
import SearchContainer from "@/Components/Layout/SearchContainer.vue";
import Card from "@/Components/Card.vue";

const page = usePage();
const user = page.props.auth.user;

</script>

<template>
    <Layout>
        <SearchContainer :type="'favourite'"></SearchContainer>
    </Layout>
</template>

