<template>
    <a v-if="props.href"
        :href="props.href"
        :class="computedClass"
    >
        <slot>{{ label }}</slot> <!-- You can pass label text or a slot for custom content -->
    </a>
    <button
        v-if="!props.href"
        :type="type"
        :class="computedClass"
        :disabled="disabled"
        @click="$emit('click')"
    >
        <slot>{{ label }}</slot> <!-- You can pass label text or a slot for custom content -->
    </button>
</template>

<script setup>
import { computed } from 'vue'

// Define props for the component
const props = defineProps({
    label: String,  // Text to display on the button
    href: {
        default: null  // Default href is null, can be a string representing the link URL
    },
    block: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: 'button'  // Default type is 'button', can be 'submit' or 'reset'
    },
    class: String,  // Custom CSS classes
    disabled: {
        type: Boolean,
        default: false  // Button is enabled by default
    },
    size: {
        type: 'string',
        default: 'md', // Default variant is 'md', available sizes 'xs', 'sm', 'lg', 'xl'
    },
    rounded: {
        type: String,
        default: 'md',
    },
    variant: {
        type: String,
        default: 'primary'  // Default variant is 'primary', can be 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'
    },
})

// Compute the button classes (allow for custom class overrides)
const computedClass = computed(() => {
    return `btn btn-${props.variant} btn-${props.size} ${props.block ? 'btn-block' : undefined} ${props.rounded ? `rounded-${props.rounded}` : 'rounded-md'} text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 ${props.class || ''} ${props.disabled ? 'opacity-50 cursor-not-allowed' : ''}`
})
</script>

<style>
/* Add any scoped styles here */
</style>
