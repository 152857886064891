<script setup>
import { ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import Layout from '../Layouts/Default.vue';
import { Head } from '@inertiajs/vue3';
import PaymentForm from "@/Components/Yoclo/PaymentForm.vue";
import Logo from "@/Components/Logo.vue";

const page = usePage();
const plan = ref(page.props.plan || 'Standard');
const details = ref(page.props.details || { price: 'TBD' });
const user = page.props.auth.user;
const stripeKey = page.props.stripeKey;
</script>

<template>
    <Head>
        <title>Choose Plan Tier Payment - Yoclo</title>
        <meta name="description" content="Complete your payment for the selected plan tier at Yoclo." />
    </Head>

    <Layout>
        <div class="h-screen pt-16 bg-black text-white">
            <div class="container mx-auto max-w-3xl p-8">
                <a href="/login" class="block text-center mb-10">
                    <logo class="mx-auto" />
                </a>

                <h2 class="text-3xl font-semibold text-center mb-8">Complete Your Payment</h2>

                <div class="bg-gray-900 p-6 rounded-lg shadow-inner mb-8">
                    <h3 class="text-xl font-medium text-gray-200 mb-4">Your Selected Plan</h3>
                    <div class="flex justify-between items-center mb-4">
                        <span class="text-lg font-semibold">{{ plan }} Plan</span>
                        <span class="text-lg font-semibold text-indigo-400">£{{ details.price }}/mo</span>
                    </div>

                    <h4 class="text-xl font-medium text-gray-200 mb-4">Your Details</h4>
                    <ul class="text-lg text-gray-400 mb-6 space-y-2">
                        <li><strong class="text-gray-200">Brand Name:</strong> {{ user?.name ?? 'n/a' }}</li>
                        <li><strong class="text-gray-200">First Name:</strong> {{ user?.first_name ?? 'n/a' }}</li>
                        <li><strong class="text-gray-200">Last Name:</strong> {{ user?.last_name ?? 'n/a' }}</li>
                        <li><strong class="text-gray-200">Email:</strong> {{ user?.email ?? 'n/a' }}</li>
                    </ul>
                </div>

                <div class="bg-gray-900 p-6 rounded-lg shadow-inner mb-8">
                    <h3 class="text-xl font-medium text-gray-200 mb-4">Payment Information</h3>
                    <p class="text-gray-400 mb-4">Please enter your payment details below to complete your subscription.</p>

                    <payment-form :stripe-key="stripeKey" />
                </div>

                <p class="text-center text-gray-500 text-sm mt-6">
                    By completing your payment, you agree to our
                    <a href="#" class="text-indigo-400 underline">Terms and Conditions</a>.
                </p>
            </div>
        </div>
    </Layout>
</template>
