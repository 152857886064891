<script setup>
import { useForm } from '@inertiajs/vue3';
import { Head } from '@inertiajs/vue3';
import Layout from "@/Layouts/Admin.vue";
import TopBar from "@/Components/Admin/TopBar.vue";

// Inertia form setup
const form = useForm({
    account_type: '',
    name: '',
    email: '',
});

// Function to handle form submission
const submit = () => {
    form.post('/admin/users');
};
</script>

<template>
    <Head>
        <title>Create User</title>
    </Head>
    <Layout>
        <top-bar title="Create User"></top-bar>
        <div class="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-md mt-8 text-black">
            <form @submit.prevent="submit">
                <div class="mb-4">
                    <label for="account_type" class="block text-sm font-medium text-gray-700">Account Type</label>
                    <select
                        v-model="form.account_type"
                        id="account_type"
                        class="mt-1 bg-white block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <option value="" disabled>Select account type</option>
                        <option selected value="admin">Admin</option>
                        <option value="brand">Brand</option>
                        <option value="creator">Creator</option>
                        <option value="agency">Agency</option>
                    </select>

                    <div v-if="form.errors.account_type" class="text-red-500 text-sm mt-2">
                        {{ form.errors.account_type }}
                    </div>
                </div>

                <!-- Name Field -->
                <div class="mb-4">
                    <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
                    <input
                        v-model="form.name"
                        type="text"
                        id="name"
                        class="mt-1 bg-white block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Enter user's name"
                    />
                    <!-- Show validation error for name -->
                    <div v-if="form.errors.name" class="text-red-500 text-sm mt-2">{{ form.errors.name }}</div>
                </div>

                <!-- Email Field -->
                <div class="mb-4">
                    <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
                    <input
                        v-model="form.email"
                        type="email"
                        id="email"
                        class="mt-1 bg-white block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Enter user's email"
                    />
                    <!-- Show validation error for email -->
                    <div v-if="form.errors.email" class="text-red-500 text-sm mt-2">{{ form.errors.email }}</div>
                </div>

                <!-- Submit Button -->
                <div class="mt-6">
                    <button
                        type="submit"
                        class="w-full bg-green-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
                        :disabled="form.processing"
                    >
                        Create User
                    </button>
                </div>
            </form>
        </div>
    </Layout>
</template>
